export const groupDispensariesByCity = dispensaries => {
	if (dispensaries.length === 0) {
		return []
	}
	return dispensaries.length === 1
		? // handle only one dispensary
		  { [dispensaries[0].city]: [{ ...dispensaries[0] }] }
		: dispensaries.reduce((cities, currentObj, index) => {
				const { city } = currentObj
				if (index === 1) {
					// first iteration, set the object with key of the city

					return { [city]: [{ ...currentObj }] }
				}
				if (typeof cities[city] !== 'undefined') {
					// if city key exists push the dispensary onto it
					const { [city]: cityArray, ...rest } = cities
					cityArray.push(currentObj)
					return { ...rest, [city]: cityArray }
				}
				// city doesn't exist so create a key with one dispensary in it
				return { ...cities, [city]: [{ ...currentObj }] }
		  })
}
