import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import toSlugCase from 'to-slug-case'
import states from 'utils/states'
import { groupDispensariesByCity } from 'utils/dispensaryDataHelpers'
import DispensaryListTemplateBase from 'templates/dispensaryListingBase'

function getDispensariesFromProps(data) {
	const { pagedDispensaries = [] } = data
	if (!pagedDispensaries || !pagedDispensaries.edges) {
		return []
	}
	return pagedDispensaries.edges.map(edge => ({ ...edge.node.dispensary }))
}

function DispensaryListingTemplate({ data, location, pageContext }) {
	const { state } = pageContext
	const { allCities = null } = data
	const [cityEntities, setCityEntities] = useState([])
	const [dispensaries, setDispensaries] = useState([])

	useEffect(() => {
		setDispensaries(getDispensariesFromProps(data))
		const cityData = (allCities && allCities.edges) || []
		const cities = Object.keys(groupDispensariesByCity(cityData.map(edge => ({ ...edge.node.dispensary }))))
		setCityEntities(
			cities.map(city => ({
				name: city,
				url: `/dispensaries/${state.toLowerCase()}/${toSlugCase(city)}`,
			}))
		)
	}, [])

	const currentState = states.filter(s => s.abbr === state)[0]
	const {
		fullName,
		geoLocation: { latitude, longitude },
		defaultMapZoom,
	} = currentState

	return (
		<DispensaryListTemplateBase
			defaultCenter={[latitude, longitude]}
			defaultMapZoom={defaultMapZoom}
			dispensaries={dispensaries}
			entities={cityEntities}
			entityListTitle={`Dispensaries By City`}
			location={location}
			pageContext={pageContext}
			pageTitle={`Find Marijauna Dispensaries in ${fullName}`}
			pageHeader={`Dispensaries in ${fullName}`}
		/>
	)
}

DispensaryListingTemplate.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
}

export const pageQuery = graphql`
	query dispensariesCitiesByState($state: String!) {
		pagedDispensaries: allDispensariesJson(
			filter: { dispensary: { state: { eq: $state } } }
			sort: { fields: [dispensary___city, dispensary___name] }
		) {
			edges {
				node {
					dispensary {
						id
						averageRating
						address1
						address2
						city
						name
						phone
						geoLocation {
							_latitude
							_longitude
						}
						state
						slug
						totalReviews
						zip
					}
				}
			}
		}
		allCities: allDispensariesJson(
			filter: { dispensary: { state: { eq: $state } } }
			sort: { fields: dispensary___city }
		) {
			edges {
				node {
					dispensary {
						city
					}
				}
			}
		}
	}
`

export default DispensaryListingTemplate
